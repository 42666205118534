.container-content {
    min-height: 100vh;
}

.gradient {
    --size: 250px;
    --speed: 50s;
    --easing: cubic-bezier(0.8, 0.2, 0.2, 0.8);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    width: var(--size);
    height: var(--size);
    filter: blur(calc(var(--size) / 5));
    background-image: linear-gradient(hsl(158, 82, 57, 85%), hsl(252, 82, 57));
    animation: rotate var(--speed) var(--easing) alternate infinite;
    border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
}

@media (min-width: 720px) {
    .gradient {
        --size: 500px;
    }
}

.section-welcome {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;
    min-height: 85vh;
    &-about {
        min-height: 95vh;
    }
}

.main-logo {
    --bs-aspect-ratio: unset;
    width: 216px;
    height: 216px;
    @media (min-width: 768px) {
        --bs-aspect-ratio: 100%;
    }
    @media (min-width: 992px) {
        width: 295px;
        height: 295px;
    }
    @media (min-width: 1200px) {
        width: 356px;
        height: 356px;
    }
    & img {
        width: 100%;
        height: 100%;
    }
}
